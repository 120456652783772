import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogPostCard from "../components/blog-post-card"
import {
  FaTwitter,
  FaFacebook,
  FaEnvelope,
  FaPinterest,
  FaLinkedin,
} from "react-icons/fa"
import { ShareButtonRectangle, ShareBlockStandard } from "react-custom-share"
import "../css/typography.css"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allContentfulPost.edges

  const shareBlockProps = {
    url: "https://everythinglucia.com",
    button: ShareButtonRectangle,
    buttons: [
      { network: "Twitter", icon: FaTwitter },
      { network: "Facebook", icon: FaFacebook },
      { network: "Email", icon: FaEnvelope },
      {
        network: "Pinterest",
        icon: FaPinterest,
        media:
          "https://everythinglucia.com/static/profile-pic-76233c178f5ed83078a06276c972c32b.jpeg",
      },
      { network: "Linkedin", icon: FaLinkedin },
    ],
    text: `Everything Lucia`,
    longtext: `Take a look at this super blog I have just found.`,
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      {posts &&
        posts.map(({ node }) => {
          return <BlogPostCard post={node} />
        })}
      <hr />
      <div
        style={{
          margin: "20px auto",
          padding: "10px 0",
        }}
      >
        <ShareBlockStandard {...shareBlockProps} />
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPost {
      edges {
        node {
          title
          subtitle
          slug
          date
          category {
            title
            slug
          }
          image {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
